import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useMediaQuery } from '../../hooks/use-media-query';

const projects = {
    header: [
        { title: 'Year', spacing: 'tcell-sm' },
        { title: 'Project', spacing: 'tcell-md' },
        { title: 'Skills', spacing: 'tcell-lg' },
        { title: 'URL', spacing: 'tcell-lg' },
    ],
    content: [
        { year: '2024', title: 'Company 1', skills: ['JavaScript', 'TypeScript', 'React', 'Storybook'], url: 'https://www.example.com' },
        {
            year: '2018',
            title: 'Company 1',
            skills: ['JavaScript', 'TypeScript', 'React', 'Storybook', 'HTML', 'SCSS', 'WordPress', 'Node.js', 'PHP'],
            url: 'https://www.example.com',
        },
    ],
};

export const Archive = () => {
    const huge = useMediaQuery('(min-width: 1024px)');
    return (
        <div className="w-100">
            <div className="view-full selected-external-link-text d-flex align-items-center fs-5">
                <Link
                    className="transition-all cursor-pointer"
                    to="/">
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Gustavo Celestino
                </Link>
            </div>
            <h1>All projects</h1>
            <table className="w-100 border-collapse text-start">
                <thead>
                    <tr>
                        {projects.header.map(
                            (header, index) =>
                                (huge || header.title === 'Year' || header.title === 'Project') && (
                                    <th
                                        className={`${header.spacing} py-3 fw-bold primary-color`}
                                        key={`header_${index}`}>
                                        {header.title}
                                    </th>
                                )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {projects.content.map((project, index) => (
                        <tr
                            className="table-border-bottom vertical-tx-top"
                            key={`project_${index}`}>
                            <td className="py-4">{project.year}</td>
                            {huge && <td className="py-4 fw-bold primary-color">{project.title}</td>}
                            {huge && (
                                <td className="py-4">
                                    <ul className="flex-wrap d-flex list-style-none m-0 p-0 justify-content-start">
                                        {project.skills.map((skill, index) => (
                                            <li key={`skills_${index}`}>
                                                <div className="skills_content mb-3 py-1 px-2 f2-medium rounded-pill fs-px-2 lh-sm me-2">{skill}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            )}
                            <td className="py-4 external-link-text">
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="transition-all d-flex align-items-center"
                                    href={project.url}>
                                    {project.url}
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
