import { Link } from 'react-router-dom';
import { SocialMedia } from '../SocialMedia';
import './styles.scss';
import { useLocation } from 'react-router-dom';

export const Presentation = ({ info }) => {
    const { name, role, description } = info;

    const location = useLocation();

    return (
        <header className="pt-0 flex-1 d-flex flex-column justify-content-between py-12">
            <div>
                <div className="max-width-2">
                    <h1>
                        <Link to="/">
                            <span className="fw-bold fs-1">{name}</span>
                        </Link>
                    </h1>
                    <h3 className="fs-5 fw-semibold">{role}</h3>
                    <p className="fs-6 fw-normal">{description}</p>
                </div>
                <div className="d-flex flex-column mt-5 text-uppercase">
                    <Link
                        className={`px-0 d-flex align-items-center menu-link ${location.hash === '#about' ? 'active' : ''}`}
                        to="/#about">
                        <span className="line transition-all"></span>
                        <span className="menu-link-text">Sobre</span>
                    </Link>
                    <Link
                        className={`py-2 px-0 d-flex align-items-center menu-link ${location.hash === '#experience' ? 'active' : ''}`}
                        to="/#experience">
                        <span className="line transition-all"></span>
                        <span className={`menu-link-text ${location.hash === '#experience' ? 'active' : ''}`}>Experiências</span>
                    </Link>
                    {/* <Link
                        className={`px-0 d-flex align-items-center menu-link ${location.hash === '#project' ? 'active' : ''}`}
                        to="/#project">
                        <span className="line transition-all"></span>
                        <span className={`menu-link-text ${location.hash === '#project' ? 'active' : ''}`}>Projetos</span>
                    </Link> */}
                </div>
            </div>
            <SocialMedia />
        </header>
    );
};
