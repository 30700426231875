import React, { useState } from 'react';
import './styles.scss';
import { About } from '../../components/About';
import { Presentation } from '../../components/Presentation';
import { Experience } from '../../components/Experience';
import { Projects } from '../../components/Projects';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Link } from 'react-router-dom';

const initialinfo = {
    name: 'Gustavo Celestino',
    role: 'Frontend Engineer',
    description: 'Especialista em React, React Native, HTML, CSS and JavaScript para aplicações mobile e web.',
    links: [
        { to: '/about', label: 'About' },
        { to: '/experience', label: 'Experience' },
        { to: '/project', label: 'Project' },
    ],
};

export const Home = () => {
    const [info] = useState(initialinfo);

    const huge = useMediaQuery('(min-width: 1024px)');

    return (
        <>
            {huge && (
                <div className="me-5 vh-100 position-sticky top-0">
                    <div className="d-flex align-items-start justify-content-start">
                        <img
                            className="align-self-center profile-picture mb-4"
                            src="/profile_picture.png"
                            alt="Profile"
                        />
                    </div>
                    <Presentation info={info} />
                </div>
            )}
            <main className={`flex-1-5 w-50 d-flex flex-column ${huge ? 'max-width-normal' : 'max-width-none'}`}>
                {!huge && (
                    <div className="max-width-2">
                        <div className="d-flex align-items-start justify-content-center">
                            <img
                                className="align-self-center profile-picture-small mb-4"
                                src="/profile_picture.png"
                                alt="Profile"
                            />
                        </div>
                        <h1 className="text-center">
                            <Link to="/">
                                <span className="fw-bold fs-1">{initialinfo.name}</span>
                            </Link>
                        </h1>
                        <h3 className="fs-5 fw-semibold text-center">{initialinfo.role}</h3>
                        <p className="fs-6 fw-normal text-center">{initialinfo.description}</p>
                    </div>
                )}
                <About />
                <Experience />
                {/* <Projects /> */}
            </main>
        </>
    );
};
