import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';

const experiences = [
    {
        title: 'Frontend Engineer',
        company: 'BitGrow Tecnologia',
        startDate: '2024',
        endDate: 'Present',
        description:
            'Telas feitas para aplicativos web e mobile para o mercado farmacêutico com enfoque em semântica e código estrutural para uma reutilização futura de novos desenvolvedores.',
        skills: ['JavaScript', 'TypeScript', 'Redux-Saga', 'React', 'HTML', 'CSS', 'React Native', 'Tailwind', 'NextJS', 'PHP', 'Yii'],
        url: 'https://bitgrow.com.br',
    },
    {
        title: 'Mobile Frontend Engineer',
        company: 'Onnitech',
        startDate: '2022',
        endDate: '2024',
        description:
            'Trabalhei no mercado financeiro criando aplicações web e mobile para o setor de crédito consignado em uma fintech.',
        skills: ['JavaScript', 'TypeScript', 'React', 'HTML', 'SCSS/LESS', 'Redux-Saga', 'KONG', 'React Native'],
        url: 'https://onnitech.com.br',
    },
    {
        title: 'Frontend Engineer',
        company: 'Pumpkin',
        startDate: '2021',
        endDate: '2022',
        description:
            'Desenvolvimento de aplicativos mobile e web para o para diversas empresas terceirizadas, com enfoque em integrações de grande porte, sendo uma delas o OpenBank para o banco BMG.',
        skills: ['JavaScript', 'TypeScript', 'Angular', 'HTML', 'CSS', 'Bootstrap', 'Flutter', 'React Native'],
        url: 'https://pumpkintech.com.br',
    },
];

export const Experience = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#experience') {
            const element = document.getElementById('experience');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const huge = useMediaQuery('(min-width: 1024px)');
    const medium = useMediaQuery('(min-width: 640px)');

    return (
        <div
            className={`mt-12 ${huge ? 'p-3' : 'py-5 px-0'}`}
            id="experience"
            aria-label="Experience">
            {!huge && <h2 className="fs-5 fw-semibold text-white text-uppercase mb-3">Experiência</h2>}
            <ul className="d-flex flex-column list-style-none ps-0">
                {experiences &&
                    experiences.map((experience, index) => (
                        <li key={`experience_${index}`}>
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                className={`transition-all py-3 block d-flex mb-5 ${medium ? 'flex-row' : 'flex-column'}`}
                                href={experience.url}>
                                <header className="flex-1 min-width-xs-tiny">
                                    <span className="vertical-top text-uppercase common-text-color fs-px-2 fw-semibold">
                                        {experience.startDate} - {experience.endDate}
                                    </span>
                                </header>
                                <div className="d-flex flex-column flex-3">
                                    <h3 className="block_title fs-px-3">
                                        {experience.title} - {experience.company} <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </h3>
                                    <p className="lh-sm fs-px-3">{experience.description}</p>
                                    <ul className="flex-wrap d-flex list-style-none ps-0 justify-content-start">
                                        {experience.skills.map((skill, index) => (
                                            <li key={`skills_${index}`}>
                                                <div className="skills_content mb-3 py-1 px-2 f2-medium rounded-pill fs-px-3 lh-sm me-2">{skill}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </a>
                        </li>
                    ))}
            </ul>
            <div className={`view-full external-link-text d-flex align-items-center fs-5 ${huge ? 'p-3' : 'py-5 px-0'}`}>
                <a
                    className="transition-all cursor-pointer"
                    href="/resume.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    Veja o currículo completo
                    <FontAwesomeIcon icon={faArrowRight} />
                </a>
            </div>
        </div>
    );
};
