import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';

const paragraphs = [
    `Sou um desenvolvedor front-end com experiência mobile, web e em fintechs. Iniciei minha carreira na empresa Pumpkin, onde construi aplicativos de grande escala como o OpenBank para o banco BMG.`,
    `Após isso, fui contratado pela empresa Onnitech, onde trabalhei no desenvolvimento de aplicativos em projetos de grande importância, como o Alelo Pod, Antecipação Salarial e Pix direto e Pix indireto`,
    `Minha posição atual na empresa BitGrow me permite aprimorar minhas habilidades em React, React Native, HTML, CSS, and JavaScript, enquanto colaboro com uma equipe de desenvolvedores talentosos para criar aplicativos móveis e web de alta qualidade. no mercado farmacêutico.`,
];

export const About = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#about') {
            const element = document.getElementById('about');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const huge = useMediaQuery('(min-width: 1024px)');

    return (
        <div
            className={`${huge ? 'p-3' : 'py-5 px-0'}`}
            id="about"
            aria-label="About">
            {!huge && <h2 className="fs-5 fw-semibold text-white text-uppercase mb-3">Sobre</h2>}
            {paragraphs &&
                paragraphs.map((paragraph, index) => (
                    <p
                        key={index}
                        dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                ))}
        </div>
    );
};
